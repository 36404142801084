import { default as _91id_9320XcLAViM0Meta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/attachments/[id].vue?macro=true";
import { default as index6NYXA5MML9Meta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/blowing-machine/index.vue?macro=true";
import { default as indexGgqjOhGoqeMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/bottle-production/index.vue?macro=true";
import { default as indexaoNlr4AZB2Meta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/bucket-production/index.vue?macro=true";
import { default as indexpXrb66YLWZMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/index.vue?macro=true";
import { default as _91id_93gnQjytmcBlMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/accesorios/[id].vue?macro=true";
import { default as indexYuYNl9O8WiMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/botellas/index.vue?macro=true";
import { default as indexx4vkBCBwE5Meta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/cucharas/index.vue?macro=true";
import { default as indexEyMYAkczVEMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/index.vue?macro=true";
import { default as indexddTlfLmQ7BMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/maquinas-de-soplado-de-botellas/index.vue?macro=true";
import { default as indexuu2DPfbJq0Meta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/flakonfuvo-gepgyartas/index.vue?macro=true";
import { default as indexP597xj4HsTMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/flakongyartas/index.vue?macro=true";
import { default as indexcpjP9uIckNMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/index.vue?macro=true";
import { default as indexzv9N2kZqWxMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/kanalgyartas/index.vue?macro=true";
import { default as _91id_93kfSYOm0I9uMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/szerelekek/[id].vue?macro=true";
import { default as indexOTVhijLgq9Meta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/index.vue?macro=true";
import { default as _91id_93E4j333GkTGMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/accesorii/[id].vue?macro=true";
import { default as indexaTLwl9xv5DMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/cupe/index.vue?macro=true";
import { default as index8WLeqrXbHnMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/flacoane/index.vue?macro=true";
import { default as indexNaMS85tzVAMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/index.vue?macro=true";
import { default as indexcHpnTrcIdIMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/masini-de-suflat-flacoane/index.vue?macro=true";
import { default as indexJZKWCva2LzMeta } from "/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/thanks/index.vue?macro=true";
export default [
  {
    name: "en-attachments-id",
    path: "/en/attachments/:id()",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/attachments/[id].vue").then(m => m.default || m)
  },
  {
    name: "en-blowing-machine",
    path: "/en/blowing-machine",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/blowing-machine/index.vue").then(m => m.default || m)
  },
  {
    name: "en-bottle-production",
    path: "/en/bottle-production",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/bottle-production/index.vue").then(m => m.default || m)
  },
  {
    name: "en-bucket-production",
    path: "/en/bucket-production",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/bucket-production/index.vue").then(m => m.default || m)
  },
  {
    name: "en",
    path: "/en",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/en/index.vue").then(m => m.default || m)
  },
  {
    name: "es-accesorios-id",
    path: "/es/accesorios/:id()",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/accesorios/[id].vue").then(m => m.default || m)
  },
  {
    name: "es-botellas",
    path: "/es/botellas",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/botellas/index.vue").then(m => m.default || m)
  },
  {
    name: "es-cucharas",
    path: "/es/cucharas",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/cucharas/index.vue").then(m => m.default || m)
  },
  {
    name: "es",
    path: "/es",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/index.vue").then(m => m.default || m)
  },
  {
    name: "es-maquinas-de-soplado-de-botellas",
    path: "/es/maquinas-de-soplado-de-botellas",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/es/maquinas-de-soplado-de-botellas/index.vue").then(m => m.default || m)
  },
  {
    name: "hu-flakonfuvo-gepgyartas",
    path: "/hu/flakonfuvo-gepgyartas",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/flakonfuvo-gepgyartas/index.vue").then(m => m.default || m)
  },
  {
    name: "hu-flakongyartas",
    path: "/hu/flakongyartas",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/flakongyartas/index.vue").then(m => m.default || m)
  },
  {
    name: "hu",
    path: "/hu",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/index.vue").then(m => m.default || m)
  },
  {
    name: "hu-kanalgyartas",
    path: "/hu/kanalgyartas",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/kanalgyartas/index.vue").then(m => m.default || m)
  },
  {
    name: "hu-szerelekek-id",
    path: "/hu/szerelekek/:id()",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/hu/szerelekek/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ro-accesorii-id",
    path: "/ro/accesorii/:id()",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/accesorii/[id].vue").then(m => m.default || m)
  },
  {
    name: "ro-cupe",
    path: "/ro/cupe",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/cupe/index.vue").then(m => m.default || m)
  },
  {
    name: "ro-flacoane",
    path: "/ro/flacoane",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/flacoane/index.vue").then(m => m.default || m)
  },
  {
    name: "ro",
    path: "/ro",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/index.vue").then(m => m.default || m)
  },
  {
    name: "ro-masini-de-suflat-flacoane",
    path: "/ro/masini-de-suflat-flacoane",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/ro/masini-de-suflat-flacoane/index.vue").then(m => m.default || m)
  },
  {
    name: "thanks",
    path: "/thanks",
    component: () => import("/Users/zoltangyurasz/Project/POLIOL_HONLAP_SSR/SOURCE/pages/thanks/index.vue").then(m => m.default || m)
  }
]