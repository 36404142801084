<script setup>
  import { useWebStore } from '@/store/web.js'
  const store = useWebStore();
  const { $viewport } = useNuxtApp()
  const route = useRoute();
  

  watch($viewport.breakpoint, (newBreakpoint, oldBreakpoint) => {
    console.log('Breakpoint updated:', oldBreakpoint, '->', newBreakpoint)
    updateScreenSize(newBreakpoint);
  })


  updateScreenSize($viewport.breakpoint.value)
  

function updateScreenSize(n){
  if(['mobile','mobileMedium'].includes(n)){
      store.overallSetting('mobile')
    }
    else if(n === 'mobileWide'){
      store.overallSetting('mobileL')
    }
    else if(n === 'tablet'){
      store.overallSetting('tabletP')
    }
    else if(n === 'desktop'){
      store.overallSetting('laptop')
    }
    else if(['desktopMedium','desktopWide'].includes(n)){
      store.overallSetting('desktop')
    }
}

</script>
<template>
  <div>
    <NuxtLayout>
      <NuxtPage
      id="app"
          @click="store.navOpen? store.navOpen = false:''"
          :class="store.screenSize"
          />
    </NuxtLayout>
  </div>
</template>
<style lang="scss">
  body{
    margin: unset !important;
  }
  #app{
    --dark-font-color:#2c3e50;
    --orange-color: #F67013;
    --dark-background-color: rgb(21,20,28);

        //FONTOK
    @font-face {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("/font/Inter-Regular.woff2") format("woff2");
    }
    @font-face {
      font-family: "Space-Grotesk";
      font-style: normal;
      font-weight: 400;
      font-display: swap;
      src: url("/font/SpaceGrotesk-VariableFont_wght.ttf") format("woff2");
    }
    @font-face { font-family: "Inter-ExtraBold";
      font-style: normal;
      font-weight: 4800;
      font-display: swap;
      src: url("/font/Inter-ExtraBold.woff2") format("woff2");
    }
    @font-face { font-family: "Inter-Medium";
      font-style: normal;
      font-weight: 500;
      font-display: swap;
      src: url("/font/Inter-Medium.woff2") format("woff2");
    }

    :root { font-family: 'Inter', sans-serif; }
    @supports (font-variation-settings: normal) {
      :root { font-family: 'Inter var', sans-serif; }
    }

    //font-family: Avenir, Helvetica, Arial, sans-serif;
    //font-family: proxima-nova, sans-serif;
    font-family: 'Inter', sans-serif;
    //font-family: proxima-nova, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color:var(--dark-font-color);
    background-color: white;
    //height: 100dvh;
    //width: 100vw;
    margin: 0 auto;
    overflow-x: hidden;
  }

</style>